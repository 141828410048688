.alert{
  margin: 25px;
  width: 300px;
  position: fixed;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  margin-bottom: 15px;
  right: 0;
  top: 0;
  z-index: 1;
  transition: all 1s ease-in-out;
}
/* addmember page start here*/
.adddimage-content,
.adddmember-content{
  height: 100%;
  width: 100%;
  display: flex;
  align-items:center;
  justify-content: center;
}
.addimg-content-inside,
.adddmember-content form{
  width: 50%;
  height: 400px;
  display: flex;
  flex-direction: column;
  margin: 100px 100px;
}
.adddimage-content ui5-file-uploader,
.adddmember-content form ui5-file-uploader,
.adddmember-content form input{
  margin-top: 5px;
  margin-left: 5px;
}

/* addmember page start here*/


.delete-member-label{
  color: black;
  font-size: 15px;
  font-weight: 600;
}
