/* groupmember page code starts*/
.home-page{
    height: 100%;
    width: 100%;
}

.groupmember-main-div{
    height: 100%;
    width: 100%; 
}

.gm-top-content-div{
    text-align: center;
}
.gm-top-content-div a ui5-button{
    margin: 10px 10px;
}
.grid-div{
    margin: 20px 20px;
}

.member-grid{
    height: 100%;
    width: 100%;
}
.member-card{
    height: 620px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 10px;
}

.member-details{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.member-img{
    height: 60%;
    width: 75%;
    padding: 5px 5px;
    overflow: hidden;
}
.member-img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;   
}

.member-text-details p{
    font-weight:600;
}
.member-text-details p span {
    margin-left:10px;
    font-weight: 400;
}

/* groupmember page code ends*/

/* news page code starts*/
.courses-div{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
}
.courses-div-content{
    margin: 10px 10px;
    width: 70%;
    height: 35%;
    display: flex;
    justify-content: center;
    gap: 1.0rem;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
    /* align-items: center; */
    /* background-color: rgb(249, 230, 196); */
}
#user-type{
    width: 100%;
}
.image-content{
    width: 30%;
    height: 100%;
}
.text-content a{
    text-decoration: none;
    color: #000;
}
.image-content img{
    width: 100%;
    height: 100%;
}   
.text-content{
    padding: 5px 5px;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    color: black;
    
}
.text-content h2{
    font-size: 25px;
    font-weight: 500;
    font-weight: bold;
    
}
.module-text{
    font-weight: 500;
}
.date-author-details {
    /* margin-right: 14px; */
    color: black;
}
.date-author-details span{
    margin-left: 10px;
}
/* news page code ends*/
/* post page code starts*/
.postpage-main-div{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
}
.postpage-content-div{
    width: 70%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
}
.postpage-content-div span{
    margin-left: 15px;
}
.summary-text{
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0px;
    color:rgb(81, 80, 80);
}
.content-text{
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0px;
    
}
.postpage-content-div h1{
    font-size: 35px;
    font-weight: 500;
    font-weight: bold;
    margin: 10px 0px;
}
.postpage-content-div img{
    width: 100%;
    margin: 5px 0px;
}

.createpost-content{
    margin: 50px 50px;
}

.createpost-content form input{
    width: 100%;
    height: 35px;
    margin-top: 5px;
}
/* post page  code ends*/
.card{
    border-radius: 1rem;
    color: white;
    height: 14rem;
    width: 12rem;
    box-shadow: 2px 0px 19px 11px rgba(0,0,0,0.1);
}
.cards {
    width: 90%;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
}

.card a {
    height: 100%;
    width: 100%;
}
.card a img,
.card img{
    height: 100%;
    width: 100%;
    border-radius: 1rem;
}

.banner-div{
    width: 100%;
    margin-bottom: 5px;
}
.banner-div img{
    width: 100%;
    height: 100%;
}
.content-div-home{
    width: 100%;
    height: 400px;
    display: flex; 
    margin-top: 10px;
}
.content-div-img{
    width: 50%;
    height: 100%
}
.content-div-img img{
    width: 100%;
    height: 100%;
}

.content-div-text{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 10px;
}
.content-div-text p{
    height: 90%;
    width: 100%;
    font-weight: 300;
    font-size:14px;
    font-family:-apple-system BlinkMacSystemFont "Segoe UI" Roboto (ttf) Oxygen Ubuntu Cantarell "Fira Sans" "Droid Sans"  "Helvetica Neue"  sans-serif;
    font-weight:600;
    
}
.carousel-section{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

}
.news-content{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.research-content{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.carousel-text{
    color: black;
    font-size: 30px;
}


.moving-3d-background{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px
}
.main-text{
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
    padding: 10px 10px;
    color: white;
}

  @media (min-width: 1000px) {
    .cards { grid-template-columns: repeat(3, 1fr); }
  }
/* news page responsive code starts*/
@media screen and (max-width:1055px)
{
  .courses-div-content{
    height: 35%;
    }
    .text-content a h2{
        font-size: 23px;
    }
}
@media screen and (max-width:975px)
{
  .text-content a h2{
    font-size: 21px;
  }
  .courses-div-content{
    height: 32%;
    }
    .image-content{
        width: 27%;
    }
}
@media screen and (max-width:875px)
{
  .text-content a h2{
    margin-top: 5px;
    font-size: 17px;
  }
  .courses-div-content{
    height: 27%;
    }
    .image-content{
        width: 27%; 
    }
    .date-author-details span,
    .date-author-details ,
    .module-text{
        font-size: 13px;
    }
    .content-div-text p{
        font-size: 11px;
    }
    .main-text{
        font-size: 2.0rem;
    }

}
@media screen and (max-width:720px)
{
  .text-content a h2{
    margin-top: 5px;
    font-size: 15px;
  }
  .courses-div-content{
    height: 25%;
    }
    .image-content{
        width: 27%; 
    }
    .date-author-details span,
    .date-author-details ,
    .module-text{
        font-size: 12px;
    }
    .content-div-text p{
        font-size: 9px;
    }
    .content-div-home{
        height: 350px;
    }
    .btn-home{
        height: 30px;
        width: 150px;
    }
    .main-text{
        font-size: 1.8rem;
    }
}

@media screen and (max-width:630px)
{
  .text-content a h2{
    font-size: 12px;
  }
  .courses-div-content{
    height: 25%;
    }
    .image-content{
        width: 27%; 
    }
    .date-author-details span,
    .date-author-details ,
    .module-text{
        font-size: 11px;
    }
    .content-div-home{
        flex-direction: column;
    }
    .content-div-img{
        width: 100%;
    }
    .content-div-text{
        margin-top: 10px;
        width: 100%;
        padding: 5px 5px;
    }
    .carousel-section{
        flex-direction: column;
    }
    .news-content{
        width: 100%;
    }
    .research-content{
        width: 100%;
    }
    
}
@media screen and (max-width:500px)
{
  .text-content a h2{
    font-size: 12px;
  }
  .courses-div-content{
    width: 75%;
    height: 23%;
    }
    .image-content{
        width: 27%; 
    }
    .date-author-details span,
    .date-author-details ,
    .module-text{
        font-size: 11px;
    }
    .content-link{
        flex-direction: column;
        height: 120px;
    }
    .btn-home{
        width: 180px;
    }
    .main-text{
        font-size: 1.4rem;
    }
    .carousel-text{
        font-size: 20px;
    
    }
}
@media screen and (max-width:474px)
{
  .text-content a h2{
    margin-top: 5px;
    font-size: 10px;
  }
  .courses-div-content{
    width: 75%;
    height: 19%;
    }
    .text-content{
        padding: 3px 3px;
    }
    .image-content{
        width: 27%; 
    }
    .date-author-details span,
    .date-author-details ,
    .module-text{
        font-size: 9px;
    }
    .carousel-section{
        margin-top: 200px;
    }
    .carousel-text{
        font-size: 15px;
    
    }
}
@media screen and (max-width:400px)
{
  .text-content a h2{
    font-size: 8px;
  }
  .courses-div-content{
    width: 75%;
    height: 17%;
    }
    .text-content{
        padding: 3px 3px;
    }
    .image-content{
        width: 27%; 
    }
    .date-author-details span,
    .date-author-details ,
    .module-text{
        font-size: 7px;
    }
}
@media screen and (max-width:354px)
{
  .text-content a h2{
    font-size: 7px;
  }
  .courses-div-content{
    width: 75%;
    height: 15%;
    }
    .text-content{
        padding: 3px 3px;
    }
    .image-content{
        width: 27%; 
    }
    .date-author-details span,
    .date-author-details ,
    .module-text{
        font-size: 6px;
    }
    .content-div-home{
        height: 450px;
    }
    .member-card{
        width: 200px;
        margin: 20px 10px;
    }
    .member-details{
        width: 100%;
        height: 100%;
    }
    .member-img{
        height: 60%;
        width: 75%;
        padding: 5px 5px;
    }
    .member-img img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    
    .member-text-details p{
        font-weight: 600;
    }
    .member-text-details p span {
        margin-left:10px;
        font-weight: 400;
    }
    .carousel-text{
        font-size: 13px;
    
    }

}
@media screen and (max-width:290px)
{
  .text-content a h2{
    margin-top: 15px;
    font-size: 6px;
  }
  .courses-div-content{
    width: 75%;
    height: 12%;
    }
    .text-content{
        padding: 2px 2px;
    }
    .image-content{
        width: 27%; 
    }
    .date-author-details span,
    .date-author-details ,
    .module-text{
        font-size: 5px;
    }

}

/* news page responsive code ends*/
/* post page responsive code starts*/
@media screen and (max-width:426px)
{
    .summary-text{
        font-size: 13px;
        margin: 5px 0px;
    }
    .content-text{
        font-size: 16px;
        margin: 5px 0px;
        
    }
    .postpage-content-div h1{
        font-size: 20px;
    }
    .postpage-content-div{
        width: 90%;
    }
}
@media screen and (max-width:376px)
{
    .summary-text{
        font-size: 13px;
        margin: 5px 0px;
    }
    .content-text{
        font-size: 16px;
        margin: 5px 0px;
        
    }
    .postpage-content-div h1{
        font-size: 18px;
    }
    .postpage-content-div{
        width: 90%;
    }
}
@media screen and (max-width:354px)
{
    .summary-text{
        font-size: 13px;
        margin: 5px 0px;
    }
    .content-text{
        font-size: 16px;
        margin: 5px 0px;
    }
    .postpage-content-div h1{
        font-size: 15px;
    }
    .postpage-content-div{
        width: 90%;
    }
    .postpage-content-div p{
        font-size: 10px;
    }
}