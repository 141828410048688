
html {
  font-size: 16px;
}
.navbar-brand img{
  height: 60px;
  width: 60px;
}
.nav-link{
  font-size: 1rem;
  color: #fff;
  margin-right: 1rem;
}


